//
import Paper from "./Paper";
import Drawer from "./Drawer";
import Button from "./Button";
import SvgIcon from "./SvgIcon";
import Progress from "./Progress";
import TreeView from "./TreeView";
import Typography from "./Typography";
import CssBaseline from "./CssBaseline";

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
  return Object.assign(
    Paper(theme),
    Button(theme),
    Drawer(theme),
    SvgIcon(theme),
    TreeView(theme),
    Progress(theme),
    Typography(theme),
    CssBaseline(theme)
  );
}
