export const languageCodes = {
  af: "Afrikaans",
  sq: "Albanian",
  ar: "Arabic (Standard)",
  an: "Aragonese",
  hy: "Armenian",
  as: "Assamese",
  ast: "Asturian",
  az: "Azerbaijani",
  eu: "Basque",
  bg: "Bulgarian",
  be: "Belarusian",
  bn: "Bengali",
  bs: "Bosnian",
  br: "Breton",
  my: "Burmese",
  ca: "Catalan",
  ch: "Chamorro",
  ce: "Chechen",
  zh: "Chinese",
  cv: "Chuvash",
  co: "Corsican",
  cr: "Cree",
  hr: "Croatian",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch (Standard)",
  en: "English",
  eo: "Esperanto",
  et: "Estonian",
  fo: "Faeroese",
  fj: "Fijian",
  fi: "Finnish",
  fr: "French (Standard)",
  fy: "Frisian",
  fur: "Friulian",
  gl: "Galacian",
  ka: "Georgian",
  de: "German (Standard)",
  el: "Greek",
  gu: "Gujurati",
  ht: "Haitian",
  he: "Hebrew",
  hi: "Hindi",
  hu: "Hungarian",
  is: "Icelandic",
  id: "Indonesian",
  iu: "Inuktitut",
  ga: "Irish",
  it: "Italian (Standard)",
  ja: "Japanese",
  kn: "Kannada",
  ks: "Kashmiri",
  kk: "Kazakh",
  km: "Khmer",
  ky: "Kirghiz",
  tlh: "Klingon",
  ko: "Korean",
  la: "Latin",
  lv: "Latvian",
  lt: "Lithuanian",
  lb: "Luxembourgish",
  mk: "FYRO Macedonian",
  ms: "Malay",
  ml: "Malayalam",
  mt: "Maltese",
  mi: "Maori",
  mr: "Marathi",
  mo: "Moldavian",
  nv: "Navajo",
  ng: "Ndonga",
  ne: "Nepali",
  no: "Norwegian",
  nb: "Norwegian (Bokmal)",
  nn: "Norwegian (Nynorsk)",
  oc: "Occitan",
  or: "Oriya",
  om: "Oromo",
  fa: "Persian/Farsi",
  pl: "Polish",
  pt: "Portuguese",
  pa: "Punjabi",
  qu: "Quechua",
  rm: "Rhaeto-Romanic",
  ro: "Romanian",
  ru: "Russian",
  sz: "Sami (Lappish)",
  sg: "Sango",
  sa: "Sanskrit",
  sc: "Sardinian",
  gd: "Scots Gaelic",
  sd: "Sindhi",
  si: "Singhalese",
  sr: "Serbian",
  sk: "Slovak",
  sl: "Slovenian",
  so: "Somani",
  sb: "Sorbian",
  es: "Spanish",
  sx: "Sutu",
  sw: "Swahili",
  sv: "Swedish",
  ta: "Tamil",
  tt: "Tatar",
  te: "Teluga",
  th: "Thai",
  tig: "Tigre",
  ts: "Tsonga",
  tn: "Tswana",
  tr: "Turkish",
  tk: "Turkmen",
  uk: "Ukrainian",
  hsb: "Upper Sorbian",
  ur: "Urdu",
  ve: "Venda",
  vi: "Vietnamese",
  vo: "Volapuk",
  wa: "Walloon",
  cy: "Welsh",
  xh: "Xhosa",
  ji: "Yiddish",
  zu: "Zulu",
};
