import { Suspense, lazy } from "react";
import { useRoutes, useLocation } from "react-router-dom";
// components
import LoadingScreen from "../components/LoadingScreen";
import CheckImg from "../pages/CheckImg";

// ----------------------------------------------------------------------

const Loadable = (Component) =>
  function (props) {
    const { pathname } = useLocation();

    return (
      <Suspense
        fallback={<LoadingScreen isDashboard={pathname.includes("/check")} />}
      >
        <Component {...props} />
      </Suspense>
    );
  };

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <Check />,
    },
    {
      path: "/l/:id",
      element: <Check />,
    },
    {
      path: "/lim/:id",
      element: <CheckImg />,
    },
    { path: "*", element: <Check /> },
  ]);
}

const Check = Loadable(lazy(() => import("../pages/Check")));
