import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";
//
import configData from "../../config.json";
//
import { languageCodes } from "../../utils/languageCodes";
// ----------------------------------------------------------------------

const { BRAND } = configData;

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const slice = createSlice({
  name: "sendLocation",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // SUCCESS
    sendLocationSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function sendLocation(payload) {
  const { token, lat, lng } = payload || {};
  const queryParams = { token, lat, lng };
  const { width, height } = window.screen || {};
  const languageCode =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;
  const language = languageCodes[languageCode?.substring(0, 2)] || languageCode;
  const queryString = new URLSearchParams(queryParams).toString();
  const url = `/set/location?${queryString}`;
  const config = {
    headers: {
      "Content-Type": "application/json",
      "device-size": `${width}&${height}`,
      "user-language": `${language}`,
      brand: BRAND,
    },
  };
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(url, config);
      dispatch(slice.actions.sendLocationSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
