import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import sendLocationReducer from "./slices/sendLocation";
import sendLinkLocationReducer from "./slices/sendLinkLocation";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const rootReducer = combineReducers({
  sendLocation: sendLocationReducer,
  sendLinkLocation: sendLinkLocationReducer,
});

export { rootPersistConfig, rootReducer };
